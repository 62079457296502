<template>
  <div class="bg-gray-200 flex justify-center h-screen">
    <div class="w-50 self-center bg-white shadow rounded-lg p-6">
      <form action="#" @submit.prevent="">
        <h1 class="text-2xl">Anmelden</h1>
        <InputCom label="Benutzername" name="username" type="text" v-model="form.username">
        </InputCom>
        <InputCom label="Passwort" name="password" input-type="password" v-model="form.password" >
        </InputCom>
        <div class="border-t mt-6 pt-3">
          <AnimatedButton @click.native="signIn('button-login')"
                          :ref="'button-login'"
                          on-initial="Anmelden"
                          on-success="Angemeldet!"
                          on-error="Fehlgeschlagen!"
                          :pause="1500"
                          class="ml-2 float-right">
          </AnimatedButton>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AnimatedButton from '@/components/Util/AnimatedButton.vue';
import InputCom from '@/components/Util/InputCom.vue';
import axios from 'axios';

export default {
  name: 'Login',
  components: {
    AnimatedButton,
    InputCom,
  },
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
    };
  },
  methods: {
    signIn(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      axios.post('login', this.form).then((r) => {
        this.$store.commit('auth/SET_AUTH_TOKEN', r.data.access_token);
        this.$toast.open({
          message: 'Erfolgreich angemeldet!',
          type: 'success',
        });
        this.testLogin();
        if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
      }).catch((error) => {
        this.$toast.open({
          message: error.response.data.message,
          type: 'error',
        });
        if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
      });
    },
    testLogin() {
      axios.get('user', { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } }).then((response) => {
        this.$store.commit('auth/SET_AUTHENTICATED', true);
        this.$store.commit('auth/SET_USER', response.data);
        this.$router.push('/').catch(() => {});
        return 'success';
      }).catch(() => {
        this.$store.commit('auth/SET_AUTH_TOKEN', null);
        this.$store.commit('auth/SET_AUTHENTICATED', false);
        this.$store.commit('auth/SET_USER', null);
        return 'error';
      });
    },
  },
};
</script>

<style scoped>

</style>
